$primary: #d01035;
$primary-hover: #b4163a;
$secondary: #193a50;
$secondary-hover: darken($secondary, 10%);
$yellow: #edc540;
$highlight: #3894b9;

$font-family: "Montserrat", Arial, sans-serif;
$font-base: 16; // pixel based font base for rem calculations
$text_color: $secondary;

$font-secondary: $secondary;
$font-light: #7e7367;
$font-invert: #ffffff;
$font-light-secondary: lighten( $secondary, 4% );
$primary-background: #f0f0f0;
$secondary-background: #E0E1E2;

$success: #98be51;
$info : #64a4d7;

$radius: 5px;

$spacing: 15px;
$image: "./dist/img/";

$mobile-break: 600px;
$mobile-break-max: $mobile-break - 1;

$timing: 300ms;

// font weights
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;


// Data images

$img-info: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAclBMVEUAAABjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNdjpNeOBRnsAAAAJXRSTlMAB0C/+vGAKfWhbFDVzq+qDrgwlh/l4VpJEwXZ0YNkNenPxXNyDT67GwAAAW5JREFUSMellleSgzAQRAckcrLBOW7q+19xJeytNahl7OL9EjShp0fiEIRxXUYAorKOw0AmCLMUj6RZKE8oKrhUhXhIFDgqEUau4UPnJNcGz2jG2S8UnqMWw/8rTKEGZzSYpnnMF4wIQ/4zT1h9jq3ss2GtErlDE7iaB4fNMI2//oIhlhoD7j2vwOjPL0cquekNlK+9HM4Y0SsxA0efPjAmsz1L8Tpp4ItIbduL7QOJKWYt24phB5fYVs5hnazEQoKtncoZoqRDYLMjAihF3EiLH1S+kCIROOxsqIaOC4CqtBXD2vMBYSOGFvwDVu1ODDF4DiVtj+EThJL2ITqY91dg1PTkxj+2MdVSL4wlGCFTq15Yo9JAStTK5mF5n9vvE5sHElN+G6uu1SQiNtO9VDfL/dF1fu4avSeuApJ1wX3p0rszeV95nE+fr2FMlKcT6q1+8nfd++398O4Gmrnjprfo/D09fROYf9fgt5m5/AI+qODPm1cakAAAAABJRU5ErkJggg==';